import { Currency } from 'interfaces/Currency'
import { Language } from 'interfaces/Language'

const getLanguageByCurrency = (currency: Currency) => {
    switch (currency) {
        case Currency.EUR:
        case Currency.CHF:
            return Language.German
        case Currency.PLN:
            return Language.Polish
        case Currency.GBP:
            return Language.BritishEnglish
        case Currency.USD:
            return Language.AmericanEnglish
        case Currency.COP:
            return Language.ColombianSpanish
        case Currency.TRY:
            return Language.Turkish
        case Currency.MXN:
            return Language.MexicanSpanish
        case Currency.PEN:
            return Language.PeruvianSpanish
        case Currency.CLP:
            return Language.ChileanSpanish
        case Currency.ARS:
            return Language.ArgentineSpanish
        case Currency.RON:
            return Language.Romanian
        case Currency.BRL:
            return Language.BrazilianPortuguese
        default:
            throw new Error(`Unknown currency: ${currency}`)
    }
}

export const getCurrencyByLanguage = (language: Language) => {
    switch (language) {
        case Language.German:
        case Language.Italian:
        case Language.French:
        case Language.Spanish:
            return Currency.EUR
        case Language.Polish:
            return Currency.PLN
        case Language.BritishEnglish:
            return Currency.GBP
        case Language.AmericanEnglish:
            return Currency.USD
        case Language.Turkish:
            return Currency.TRY
        case Language.MexicanSpanish:
            return Currency.MXN
        case Language.ColombianSpanish:
            return Currency.COP
        case Language.PeruvianSpanish:
            return Currency.PEN
        case Language.ChileanSpanish:
            return Currency.CLP
        case Language.ArgentineSpanish:
            return Currency.ARS
        case Language.Romanian:
            return Currency.RON
        case Language.Greek:
            return Currency.EUR
        case Language.BrazilianPortuguese:
            return Currency.BRL
        default:
            throw new Error(`Unknown language: ${language}`)
    }
}

export const formatCurrentyAmount = (n: number, currency: Currency) =>
    new Intl.NumberFormat(getLanguageByCurrency(currency), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(n / 100)

export function formatAmount(amount: number, currency: Currency): string {
    switch (currency) {
        case Currency.EUR:
            return `${formatCurrentyAmount(amount, currency)} €`
        case Currency.CHF:
            return `${formatCurrentyAmount(amount, currency)} CHF`
        case Currency.PLN:
            return `${formatCurrentyAmount(amount, currency)} zł`
        case Currency.GBP:
            return `£${formatCurrentyAmount(amount, currency)}`
        case Currency.USD:
            return `$${formatCurrentyAmount(amount, currency)}`
        case Currency.COP:
            return `${formatCurrentyAmount(amount, currency)} COP`
        case Currency.TRY:
            return `₺${formatCurrentyAmount(amount, currency)}`
        case Currency.MXN:
            return `$${formatCurrentyAmount(amount, currency)}`
        case Currency.PEN:
            return `S/.${formatCurrentyAmount(amount, currency)}`
        case Currency.CLP:
            return `$${formatCurrentyAmount(amount, currency)}`
        case Currency.ARS:
            return `$${formatCurrentyAmount(amount, currency)}`
        case Currency.RON:
            return `RON${formatCurrentyAmount(amount, currency)}`
        case Currency.BRL:
            return `R$${formatCurrentyAmount(amount, currency)}`
        default:
            return `${amount} (unknown currency '${currency}')`
    }
}
